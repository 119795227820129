var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"添加","visible":_vm.dialogVisible,"close-on-click-modal":false,"destroy-on-close":"","width":"40%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"versionName"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'input',
          required: true,
          label: '版本名称：',
          width: '100%',
        }},model:{value:(_vm.dataForm.versionName),callback:function ($$v) {_vm.$set(_vm.dataForm, "versionName", $$v)},expression:"dataForm.versionName"}})],1),_c('el-form-item',{attrs:{"prop":"equipmentNature"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'select',
          label: '设备性质：',
          required: true,
          width: '100%',
          option: _vm.DeviceNatureDict,
        }},model:{value:(_vm.dataForm.equipmentNature),callback:function ($$v) {_vm.$set(_vm.dataForm, "equipmentNature", $$v)},expression:"dataForm.equipmentNature"}})],1),_c('el-form-item',{attrs:{"prop":"terminalType"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'slot',
          label: '产品终端：',
          required: true,
          width: '100%',
        }}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"multiple":"","placeholder":"请选择"},model:{value:(_vm.dataForm.terminalType),callback:function ($$v) {_vm.$set(_vm.dataForm, "terminalType", $$v)},expression:"dataForm.terminalType"}},_vm._l((_vm.ProductTerminal),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-form-item',{attrs:{"prop":"isEnable"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'radio',
          label: '是否启用：',
          width: '100%',
          option: [
            {
              label: '启用',
              value: true,
            },
            {
              label: '禁用',
              value: false,
            },
          ],
        }},model:{value:(_vm.dataForm.isEnable),callback:function ($$v) {_vm.$set(_vm.dataForm, "isEnable", $$v)},expression:"dataForm.isEnable"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }