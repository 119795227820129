<template>
  <el-dialog
    title="添加"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    width="40%"
  >
    <el-form ref="dataForm" :model="dataForm" :rules="rules"
      ><el-form-item prop="versionName">
        <TextInput
          v-model="dataForm.versionName"
          labelPosition="left"
          :config="{
            type: 'input',
            required: true,
            label: '版本名称：',
            width: '100%',
          }"
        >
        </TextInput>
      </el-form-item>
      <el-form-item prop="equipmentNature">
        <TextInput
          v-model="dataForm.equipmentNature"
          labelPosition="left"
          :config="{
            type: 'select',
            label: '设备性质：',
            required: true,
            width: '100%',
            option: DeviceNatureDict,
          }"
        >
        </TextInput>
      </el-form-item>
      <el-form-item prop="terminalType">
        <TextInput
          labelPosition="left"
          :config="{
            type: 'slot',
            label: '产品终端：',
            required: true,
            width: '100%',
          }"
        >
          <el-select
            style="width: 100%"
            v-model="dataForm.terminalType"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in ProductTerminal"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </TextInput>
      </el-form-item>
      <el-form-item prop="isEnable">
        <TextInput
          v-model="dataForm.isEnable"
          labelPosition="left"
          :config="{
            type: 'radio',
            label: '是否启用：',
            width: '100%',
            option: [
              {
                label: '启用',
                value: true,
              },
              {
                label: '禁用',
                value: false,
              },
            ],
          }"
        >
        </TextInput> </el-form-item
    ></el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <el-button @click="submit" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
import { DeviceNatureDict, ProductTerminal } from "@/js/const/vehicle.js";
import { addOrUpdateProductVersion } from "@/api/vehicleEquipment";
export default {
  name: "ProductVersionAddOrEditDialog",
  components: { TextInput },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      if (n && this.detailData && Object.keys(this.detailData).length) {
        this.dataForm = _.cloneDeep(this.detailData);
        if (this.dataForm.terminalType) {
          this.dataForm.terminalType = this.handleTerminalType(2);
        }
      } else {
        this.dataForm = {
          //id: null, //主键id，添加传null
          versionName: "", //版本名称
          equipmentNature: true, //设备性质(0:工机，1:商车）
          terminalType: [], //终端类型(多个,隔开)
          isEnable: true, //是否启用(0：不启用，1：启用)
        };
      }
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  data() {
    return {
      DeviceNatureDict: DeviceNatureDict,
      //终端类型列表
      ProductTerminal: ProductTerminal,
      dialogVisible: false,
      dataForm: {
        //id: null, //主键id，添加传null
        versionName: "", //版本名称
        equipmentNature: true, //设备性质(0:工机，1:商车）
        terminalType: [], //终端类型(多个,隔开)
        isEnable: true, //是否启用(0：不启用，1：启用)
      },
      rules: {
        versionName: [
          { required: true, message: "请输入版本名称", trigger: "change" },
        ],
        equipmentNature: [
          { required: true, message: "请选择设备性质", trigger: "change" },
        ],
        terminalType: [
          { required: true, message: "请选择产品终端", trigger: "change" },
        ],
        isEnable: [
          { required: true, message: "请选择是否启用", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    handleTerminalType(type = 1) {
      let res = "";
      if (type == 1) {
        res = this.dataForm.terminalType.join(",");
      } else {
        res = [];
        this.dataForm.terminalType.split(",").forEach((item) => {
          res.push(Number(item));
        });
      }
      return res;
    },
    submit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let submitData = _.cloneDeep(this.dataForm);
          submitData.terminalType = this.handleTerminalType(1);
          addOrUpdateProductVersion(submitData).then((res) => {
            this.$emit("submit");
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "提交成功",
            });
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
</style>
